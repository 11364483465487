require(['perspectiveListing', 'perspectivePreview', 'hotspotPreview', 'menu', 'templateForm', 'review', 'templateGroups', 'loadedTemplates', 'perspectiveHotspotPreview', 'imageSelectorFieldControl', 'addToCart', 'applyHotspot', 'commonColorClick', 'updateUI', 'overlay', 'salespersonFieldControl'], function
	(perspectiveListing, perspectivePreview, hotspotPreview, menu, templateForm, review, templateGroups, loadedTemplates, perspectiveHotspotPreview, imageSelectorFieldControl, addToCart, applyHotspot, commonColorClick, updateUI, overlay, salespersonFieldControl) {
	'use strict';

	var init = function () {
		// run init functions for relevant modules
		review.init();
		perspectivePreview.init();
		menu.init();
		hotspotPreview.init();
		templateGroups.init();	
		templateForm.init();
		loadedTemplates.init();
		perspectiveHotspotPreview.init();
		imageSelectorFieldControl.init();
		addToCart.init();
		applyHotspot.init();

		// run initializeUI functions for relevant modules
		perspectiveListing.initializeUI();
		perspectivePreview.initializeUI();

        review.refreshReviewUI();
        updateUI.init();

	};

	init();
});     